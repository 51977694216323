import { ImgHTMLAttributes } from 'react'

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  width?: number
  height?: number
}

const Image = ({ width, height, ...props }: ImageProps) => {
  return <img {...props} style={{ width, height }} />
}

export default Image