
'use client'
import { useState } from 'react';
import { motion } from 'framer-motion';
import styles from './style.module.css';

interface Project {
    id: number;
    title: string;
    description: string;
    imageUrl: string;
}

interface ProjectItemProps {
    project: Project;
}

const anim = {
    initial: {width: 0},
    open: {width: "auto", transition: {duration: 0.4, ease: [0.23, 1, 0.32, 1]}},
    closed: {width: 0}
}

export default function ProjectItem({ project }: ProjectItemProps) {
    const [isActive, setIsActive] = useState(false);
    const { title, imageUrl } = project;

    // Split title into two parts at the first space
    const [title1, title2] = title.split(' ', 2);

    return (
        <div 
            onMouseEnter={() => setIsActive(true)} 
            onMouseLeave={() => setIsActive(false)} 
            className={styles.project}
        >
            <p style={{ fontFamily: 'Sephir' }}>{title1}</p>
            <motion.div 
                variants={anim} 
                animate={isActive ? "open" : "closed"} 
                className={styles.imgContainer}
            >
                <img src={imageUrl} alt={title} />
            </motion.div>
            <p style={{ fontFamily: 'Sephir' }}>{title2 || ''}</p>
        </div>
    );
}
