
import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Menu, X } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'
import { Button } from './ui/button'

export default function Header() {
  const location = useLocation()
  const [mounted, setMounted] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const currentPath = location.pathname

  const navigation = [
    { name: 'Home', href: '/' },
    { name: 'Works', href: '/works' },
    // { name: 'Components', href: '/projects' }, // Hidden as requested
    { name: 'About', href: '/about' }
  ]

  useEffect(() => {
    setMounted(true)
    
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  // Prevent body scroll when menu is open
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isMenuOpen])

  if (!mounted) {
    return null
  }

  return (
    <>
      <header className="fixed top-4 left-0 right-0 z-50 mx-auto max-w-5xl px-4">
        <nav 
          className={`relative mx-auto flex h-12 items-center justify-between rounded-full px-4 transition-all duration-300 overflow-hidden group
            ${isScrolled 
              ? 'bg-white/80 shadow-[0_10px_25px_rgba(0,0,0,0.16)]' 
              : 'bg-white/60 shadow-[0_5px_15px_rgba(0,0,0,0.08)]'
            } backdrop-blur-md border border-white/20 hover:border-white/40`}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <motion.div
            className={`absolute inset-0 bg-gradient-to-r from-sunset-DEFAULT/20 to-primary-500/20 opacity-0 ${isHovering ? 'opacity-30' : 'opacity-0'} transition-opacity duration-300`}
            initial={false}
            animate={{ rotate: [0, 360] }}
            transition={{ duration: 10, repeat: Infinity, ease: "linear" }}
          />
          
          <div className="relative z-10 flex w-full items-center justify-between">
            <Link 
              to="/" 
              className="relative text-base font-bold text-gray-900"
            >
              p.s
            </Link>
            
            <div className="hidden md:flex items-center space-x-6">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`${
                    currentPath === item.href
                      ? 'text-primary-500'
                      : 'text-gray-700 hover:text-primary-500'
                  } text-sm font-medium transition-colors`}
                >
                  {item.name}
                </Link>
              ))}
              
              <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                <Button
                  onClick={() => window.location.href = "/request-quote"}
                  variant="ghost"
                  size="sm"
                  className="group relative overflow-hidden rounded-full border border-primary-500/20 hover:border-primary-500/40 hover:bg-primary-500/5"
                >
                  <span className="relative z-10 text-sm font-medium text-foreground group-hover:text-primary-500 transition-colors duration-300">Get a Quote</span>
                </Button>
              </motion.div>
            </div>

            <div className="flex md:hidden items-center space-x-2">
              <motion.button
                onClick={() => window.location.href = "/request-quote"}
                whileTap={{ scale: 0.95 }}
                className="px-3 py-1 text-xs font-medium text-foreground"
              >
                Quote
              </motion.button>

              <motion.button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="p-1 rounded-full hover:bg-gray-100/70 transition-colors"
                whileTap={{ scale: 0.95 }}
              >
                <AnimatePresence mode="wait">
                  <motion.div
                    key={isMenuOpen ? 'close' : 'menu'}
                    initial={{ rotate: -90, opacity: 0 }}
                    animate={{ rotate: 0, opacity: 1 }}
                    exit={{ rotate: 90, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="text-gray-900"
                  >
                    {isMenuOpen ? <X className="w-3.5 h-3.5" /> : <Menu className="w-3.5 h-3.5" />}
                  </motion.div>
                </AnimatePresence>
              </motion.button>
            </div>
          </div>
        </nav>
      </header>

      <AnimatePresence>
        {isMenuOpen && (
          <>
            {/* Backdrop blur layer */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="fixed inset-0 bg-black/10 backdrop-blur-sm z-40"
              onClick={() => setIsMenuOpen(false)}
            />

            {/* Mobile menu */}
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ 
                duration: 0.4,
                type: "spring",
                stiffness: 300,
                damping: 30
              }}
              className="fixed top-[4.5rem] left-4 right-4 z-50"
            >
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                transition={{ duration: 0.3 }}
                className="bg-gradient-to-b from-[#0000001a] to-[#00000026] backdrop-blur-xl rounded-2xl shadow-[0_8px_32px_rgba(0,0,0,0.06)] border border-white/10 overflow-hidden"
              >
                <div className="py-3 space-y-0.5">
                  {navigation.map((item, index) => (
                    <motion.div
                      key={item.name}
                      initial={{ x: -20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ 
                        duration: 0.4,
                        delay: index * 0.1,
                        type: "spring",
                        stiffness: 300,
                        damping: 30
                      }}
                      className="w-full text-center"
                    >
                      <Link
                        to={item.href}
                        onClick={() => setIsMenuOpen(false)}
                        className={`inline-block py-2 px-14 rounded-lg transition-all duration-300 ${
                          currentPath === item.href
                            ? 'bg-white/10 text-white'
                            : 'text-white/80 hover:bg-white/5 hover:text-white'
                        } text-sm font-medium backdrop-blur-sm`}
                      >
                        {item.name}
                      </Link>
                    </motion.div>
                  ))}
                  
                  <motion.div
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ 
                      duration: 0.4,
                      delay: navigation.length * 0.1,
                      type: "spring",
                      stiffness: 300,
                      damping: 30
                    }}
                    className="w-full text-center pt-1"
                  >
                    <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                      <Button
                        onClick={() => {
                          setIsMenuOpen(false)
                          window.location.href = "/request-quote"
                        }}
                        variant="ghost"
                        size="sm"
                        className="group relative overflow-hidden rounded-full border border-white/20 hover:border-white/30 hover:bg-white/5"
                      >
                        <span className="relative z-10 text-sm font-medium text-white group-hover:text-white/90 transition-colors duration-300">
                          Get a Quote
                        </span>
                      </Button>
                    </motion.div>
                  </motion.div>
                </div>
              </motion.div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  )
}
