
import { motion } from 'framer-motion'
import Header from '../components/Header'
import Image from '../components/Image'
import Footer from '../components/Footer'
import { AnimatedText } from '@/components/ui/animated-underline-text-one'

export default function About() {
  return (
    <div className="min-h-screen bg-white dark:bg-[#0A0A0A]">
      <Header />
      
      <main className="container mx-auto px-4 pt-24 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mx-auto max-w-4xl"
        >
          <div className="mb-12">
            <AnimatedText 
              text="About Me"
              textClassName="text-5xl font-bold mb-2"
              underlineClassName="text-primary-500"
            />
          </div>

          <div className="mb-8">
            <div className="flex items-center gap-4">
              <div className="h-3 w-3">
                <span className="inline-block h-full w-full rounded-full bg-green-500"></span>
              </div>
              <p className="text-gray-600 dark:text-gray-400">
                <a className="bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent">EPR Theatres, Anaesthesia & Critical Care Configuration Analyst</a> @ Airedale NHS Trust, Bradford Royal Infirmary & Calderdale and Huddersfield NHS Foundation Trust
                // Freelance Developer // Full-Stack Developer at{' '}
                <a href="https://www.nyasalands.com/" target="_blank" rel="noopener noreferrer" className="bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent">
                  Nyasalands
                </a>
                {' '}+{' '}
                <a href="https://ninjo.io/" target="_blank" rel="noopener noreferrer" className="bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent">
                  Ninjo
                </a>
              </p>
            </div>
          </div>

          <div className="space-y-6">
            <h1 className="text-4xl font-light leading-tight tracking-tight text-black dark:text-white sm:text-5xl">
              <span className="text-black dark:text-white inline-flex items-center gap-2">
                I
                <motion.div
                  whileHover={{ scale: 2.5, rotate: 5 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  className="inline-block w-16 h-16"
                >
                  <Image 
                    src="/lovable-uploads/177e4ab7-a5a0-4df0-9e7d-de368c752b78.png"
                    alt="Avatar"
                    className="w-full h-full object-contain"
                  />
                </motion.div>
                am
              </span>{' '}
              <span className="bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent">
              passionate about technology,
              </span>{' '}
              <span className="text-gray-700 dark:text-gray-500">I refine my front-end and design skills to </span>{' '}
              <span className="bg-gradient-to-r from-orange-400 to-rose-400 bg-clip-text text-transparent">build stunning websites </span>{' '}
              <span className="text-gray-700 dark:text-gray-500"> while also configuring </span>{' '}
              <span className="bg-gradient-to-r from-orange-400 to-rose-400 bg-clip-text text-transparent">
              EPR systems for NHS hospitals
              </span>
              <span className="text-gray-700 dark:text-gray-500"> , improving digital workflows for better patient care</span>{' '}
            </h1>

            <div className="space-y-6 text-lg text-gray-600 dark:text-gray-400">
              <p className="leading-relaxed">
                I studied my <span className="bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent">
                bachelors degree in Computer Engineering in Kayseri, Turkey</span>. During my time there 
                I also went to study for a semester at <span className="bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent">
                Politechnika Bydgoska im. Jana i Jędrzeja Śniadeckich in Bydgoszcz, Poland</span> through the Erasmus program.
              </p>

              <p className="leading-relaxed">
              I love travelling, writting, gaming, listening to music and watching tech reviews. I enjoy hanging out with friends and interacting 
              with my immediate community. <span className="bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent">
              I like this phrase I'm a simple person, city life doesn't move me {' '}
              </span>
              so much it has almost defined me as person since 2017 but i relate to the Bible verse from Jeremiah 10:23 more which says
              <span className="bg-gradient-to-r from-orange-400 to-rose-400 bg-clip-text text-transparent">
              {' '} 'O LORD, I know that the path of [life of] a man is not in 
                himself; It is not within [the limited ability of] man [even one at his best] to choose and direct his steps [in life].'
              </span> 
              </p>
              
              <p className="leading-relaxed">
                I am now <span className="bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent">deeply passionate about crafting beautiful web experiences</span> that balance aesthetics and functionality. My focus is on creating <span className="bg-gradient-to-r from-orange-400 to-rose-400 bg-clip-text text-transparent">clean, intuitive user interfaces</span> with smooth animations and responsive designs that work flawlessly across all devices. I enjoy the creative process of translating ideas into <span className="bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent">visually stunning digital solutions</span> that not only look impressive but also provide exceptional user experiences. The frontend development world continues to evolve, and I'm committed to staying at the forefront of emerging technologies and design trends.
              </p>
            </div>
          </div>
        </motion.div>
      </main>
      <Footer />
    </div>
  )
}
