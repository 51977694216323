import { useEffect, useRef, useState } from 'react'
import { useTheme } from 'next-themes'

export default function Intro() {
  const [scrollProgress, setScrollProgress] = useState(0)
  const refContainer = useRef<HTMLDivElement>(null)
  const { theme } = useTheme()

  useEffect(() => {
    const handleScroll = () => {
      if (refContainer.current) {
        const { clientHeight, offsetTop } = refContainer.current
        const screenH = window.innerHeight
        const halfH = screenH / 2
        const percentY =
          Math.min(
            clientHeight + halfH,
            Math.max(-screenH, window.scrollY - offsetTop) + halfH
          ) / clientHeight
        setScrollProgress(Math.min(4 - 0.5, Math.max(0.5, percentY * 4)))
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const scale = 1 + Math.max(0, Math.min(0.2, scrollProgress * 0.1))

  return (
    <div
      ref={refContainer}
      className={`relative z-10 ${theme === 'dark' ? 'bg-black text-white' : 'bg-white text-black'}`}
      id="intro"
    >
      <div 
        className="mx-auto flex min-h-screen max-w-5xl flex-col items-center justify-center px-10 py-40 md:py-48 text-4xl font-semibold tracking-tight md:text-6xl lg:px-20 lg:text-7xl"
        style={{ transform: `scale(${scale})`, transition: 'transform 0.1s ease-out' }}
      >
        <div className="leading-[1.15] space-y-12">
          <div className="introText" style={{ opacity: scrollProgress <= 1 ? 1 : 0.2 }}>
            I love front-end development and designing beautiful web apps.
          </div>
          <span
            className="introText inline-block after:content-['_']"
            style={{ opacity: scrollProgress > 1 && scrollProgress <= 2 ? 1 : 0.2 }}
          >
            Transforming complex design concepts into stunning, interactive web experiences
          </span>
          <span
            className="introText inline-block"
            style={{ opacity: scrollProgress > 2 && scrollProgress <= 3 ? 1 : 0.2 }}
          >
            My passion for technology drives me to stay ahead of the curve
          </span>
          <span
            className="introText inline-block"
            style={{ opacity: scrollProgress > 3 ? 1 : 0.2 }}
          >
            I dabble in SwiftUI app development when inspiration strikes
          </span>
        </div>
      </div>
    </div>
  )
}