
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import FadeUp from '../components/Animations/FadeUp';
import { AnimatedText } from '@/components/ui/animated-underline-text-one';
import { ExternalLink, Briefcase, Rocket, Bookmark } from 'lucide-react';

interface Project {
  id: number;
  title: string;
  description: string[];
  imageUrl: string;
  status?: string;
  url: string;
  type: 'client' | 'personal' | 'hybrid';
}

const projects: Project[] = [
  {
    id: 1,
    title: "NRGym",
    description: [
      "Developed a modern, responsive website for NRGym, the gym I go to, featuring nice animations and a sleek design.",
      "Implemented a dynamic photo gallery showcasing gym facilities and equipment, highlighting all the services offered.",
      "Added features that showcase all the gym offers in a visually appealing way.",
      "Implemented a timetable for Hyrox with an integrated calendar for easy scheduling.",
      "Integrated responsive design for optimal viewing on all devices.",
      "Fast development turnaround while maintaining high-quality code standards.",
    ],
    imageUrl: "/images/nrgym.gif",
    url: "https://www.nrgym.co.uk/",
    type: "client"
  },
  {
    id: 7,
    title: "In-Tech Systems Group",
    description: [
      "Developed a fast, responsive single-page website showcasing the client's IT services and solutions.",
      "Created an elegant, streamlined user experience with smooth animations and intuitive navigation.",
      "Implemented accelerated page loading techniques to ensure optimal performance and minimal load times.",
      "Designed with mobile-first approach to ensure perfect display across all device sizes.",
      "Integrated modern web technologies for a seamless user experience.",
      "Optimized for SEO to improve visibility and ranking on search engines.",
    ],
    imageUrl: "/images/intechsystemsgroup.gif",
    url: "https://www.intechsystemsgroup.com/",
    type: "client"
  },
  {
    id: 2,
    title: "Brydg Capital London",
    description: [
      "Developed the Brydg ticker component, starting with postcode-based area highlighting on London maps.",
      "Implemented live data integration showing crime rates and property prices across different London areas.",
      "Feature includes data from the Land Registry for accurate property information.",
      "Built with sharing and embedding capabilities for third-party websites.",
      "Real-time data visualization for London property market analysis."
    ],
    imageUrl: "/images/brydg.gif",
    url: "https://brydg.com/",
    type: "client"
  },
  {
    id: 3,
    title: "Nyasalands Real-Estate",
    description: [
      "Developed a revolutionary real estate platform to combat agent monopoly in Malawi.",
      "Designed and implemented a user-friendly interface for free property listings.",
      "Full-stack development including user authentication, property management, and search functionality.",
      "Empowering Malawians with direct access to property listings without agent exploitation.",
      "Complete property management system with image uploads and detailed listing features."
    ],
    imageUrl: "/images/nyasalandsfinal.gif",
    url: "https://www.nyasalands.com/",
    type: "personal"
  },
  {
    id: 4,
    title: "School-Yathu Edu-Management-Suite",
    description: [
      "Comprehensive educational management system designed for Malawian schools.",
      "Features include grade management, class organization, and academic tracking.",
      "Secure login system for teachers, students, and administrators.",
      "Similar to Canvas but tailored for local Malawian educational needs.",
      "Currently in beta development phase with ongoing feature improvements."
    ],
    imageUrl: "/images/schoolyathu.gif",
    status: "In Development (Beta)",
    url: "https://www.schoolyathu.com/",
    type: "personal"
  },
  {
    id: 5,
    title: "Ninjo.io",
    description: [
      "Full-stack mental health platform similar to Medium, but focused on mental health content.",
      "Complete anonymous posting system for sharing sensitive stories and experiences.",
      "Enhanced privacy features to protect user identities.",
      "Campaign-based content organization for mental health awareness.",
      "Currently in final testing stages with robust security measures."
    ],
    imageUrl: "/images/ninjo.png",
    status: "In Beta Testing",
    url: "https://ninjo.io/",
    type: "hybrid"
  },
  {
    id: 6,
    title: "Undefiled Religion",
    description: [
      "Full-stack charity platform developed for orphan support and community aid.",
      "Comprehensive information about charitable activities and initiatives.",
      "Planned features include donation integration and financial transparency dashboard.",
      "Will implement real-time tracking of charitable expenditures and impact.",
      "Focus on transparency and accountability in charitable operations."
    ],
    imageUrl: "/images/und.gif",
    url: "https://www.undefiledreligion.family/",
    type: "personal"
  }
];

// Project type badge component
const ProjectTypeBadge = ({ type }: { type: 'client' | 'personal' | 'hybrid' }) => {
  if (type === 'hybrid') {
    return (
      <motion.div
        className="relative inline-flex items-center justify-center mb-3"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.3 }}
      >
        <div className="px-3 py-1 rounded-full flex items-center gap-2 text-xs font-medium bg-gradient-to-r from-blue-500/10 to-purple-500/10 text-primary-500 border border-primary-500/30">
          <motion.div
            initial={{ rotate: 0 }}
            animate={{ rotate: [0, 5, 0, -5, 0] }}
            transition={{ 
              repeat: Infinity, 
              repeatDelay: 4,
              duration: 0.5 
            }}
          >
            <Bookmark className="w-3.5 h-3.5" strokeWidth={2} />
          </motion.div>
          <span>Client & Personal</span>
        </div>
        
        {/* Subtle animated glow effect */}
        <motion.div 
          className="absolute inset-0 rounded-full bg-primary-500/5 blur-md -z-10"
          animate={{ 
            scale: [1, 1.15, 1],
            opacity: [0.3, 0.6, 0.3]
          }}
          transition={{ 
            repeat: Infinity, 
            duration: 2,
            ease: "easeInOut" 
          }}
        />
      </motion.div>
    );
  }
  
  return (
    <motion.div
      className="relative inline-flex items-center justify-center mb-3"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.3 }}
    >
      <div className={`
        px-3 py-1 rounded-full flex items-center gap-2 text-xs font-medium
        ${type === 'client' 
          ? 'bg-blue-500/10 text-blue-500 border border-blue-500/30' 
          : 'bg-purple-500/10 text-purple-500 border border-purple-500/30'}
      `}>
        {type === 'client' ? (
          <>
            <motion.div
              initial={{ rotate: 0 }}
              animate={{ rotate: [0, 5, 0, -5, 0] }}
              transition={{ 
                repeat: Infinity, 
                repeatDelay: 3,
                duration: 0.5 
              }}
            >
              <Briefcase className="w-3.5 h-3.5" strokeWidth={2} />
            </motion.div>
            <span>Client Work</span>
          </>
        ) : (
          <>
            <motion.div
              initial={{ scale: 1 }}
              animate={{ scale: [1, 1.2, 1] }}
              transition={{ 
                repeat: Infinity, 
                repeatDelay: 2,
                duration: 0.6 
              }}
            >
              <Rocket className="w-3.5 h-3.5" strokeWidth={2} />
            </motion.div>
            <span>Personal Project</span>
          </>
        )}
      </div>
      
      {/* Subtle animated glow effect */}
      <motion.div 
        className={`absolute inset-0 rounded-full ${type === 'client' ? 'bg-blue-500/5' : 'bg-purple-500/5'} blur-md -z-10`}
        animate={{ 
          scale: [1, 1.15, 1],
          opacity: [0.3, 0.6, 0.3]
        }}
        transition={{ 
          repeat: Infinity, 
          duration: 2,
          ease: "easeInOut" 
        }}
      />
    </motion.div>
  );
};

export default function Works() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 }
  };

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <main className="pt-32 pb-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="mb-16">
            <AnimatedText 
              text="My Work"
              textClassName="text-5xl font-bold mb-2"
              underlineClassName="text-primary-500"
            />
          </div>
          
          <div className="space-y-32">
            {projects.map((project, index) => (
              <motion.div
                key={project.id}
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
                className="relative group"
              >
                <div className="grid md:grid-cols-2 gap-8 items-center">
                  <div className="order-1 md:order-1">
                    <motion.img
                      src={project.imageUrl}
                      alt={project.title}
                      className="w-full h-auto"
                      whileHover={{ scale: 1.02 }}
                      transition={{ duration: 0.3 }}
                    />
                  </div>
                  <motion.div 
                    className="order-2 md:order-2"
                    variants={containerVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                  >
                    {/* Project type badge */}
                    <ProjectTypeBadge type={project.type} />
                    
                    <div className="flex items-center gap-4 mb-4">
                      <a 
                        href={project.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block group"
                      >
                        <h2 className="text-3xl font-bold hover:text-primary-500 transition-colors duration-300" style={{ fontFamily: 'Sephir' }}>
                          {project.title}
                        </h2>
                      </a>
                      <motion.a
                        href={project.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="relative w-10 h-10 flex items-center justify-center"
                        whileHover={{ scale: 1.1 }}
                        initial={{ opacity: 0.8 }}
                        animate={{ opacity: 1 }}
                      >
                        {/* Circular gradient background */}
                        <motion.div 
                          className="absolute inset-0 rounded-full bg-gradient-to-tr from-primary-500/20 to-purple-500/20 border border-primary-500/30"
                          whileHover={{ 
                            scale: 1.15,
                            borderColor: "rgba(79, 70, 229, 0.5)"
                          }}
                          transition={{ duration: 0.3 }}
                        />
                        
                        {/* Pulsing circle effect */}
                        <motion.div
                          className="absolute inset-0 rounded-full bg-primary-500/10"
                          initial={{ scale: 0.8, opacity: 0 }}
                          animate={{ 
                            scale: [0.8, 1.2, 0.8], 
                            opacity: [0, 0.3, 0],
                          }}
                          transition={{ 
                            repeat: Infinity, 
                            duration: 2.5,
                            ease: "easeInOut" 
                          }}
                        />
                        
                        {/* Icon itself with floating animation */}
                        <motion.div
                          initial={{ y: 0 }}
                          animate={{ y: [-1, 1, -1] }}
                          transition={{
                            repeat: Infinity,
                            duration: 2,
                            ease: "easeInOut"
                          }}
                        >
                          <ExternalLink 
                            className="w-5 h-5 text-primary-500 stroke-primary-500" 
                            strokeWidth={1.5} 
                          />
                        </motion.div>
                      </motion.a>
                    </div>
                    {project.status && (
                      <span className="inline-block px-3 py-1 text-sm font-medium bg-primary-500/10 text-primary-500 rounded-full mb-4">
                        {project.status}
                      </span>
                    )}
                    <div className="space-y-4">
                      {project.description.map((desc, i) => (
                        <motion.p
                          key={i}
                          variants={itemVariants}
                          className="text-lg text-muted-foreground pl-4 border-l-2 border-primary-500/30 hover:border-primary-500 transition-colors duration-300"
                        >
                          {desc}
                        </motion.p>
                      ))}
                    </div>
                  </motion.div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
