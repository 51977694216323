
import { AnimatePresence, motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { ReactElement, useContext, useEffect, useRef } from 'react'
import { HiOutlineArrowNarrowDown } from 'react-icons/hi'
import FadeDown from './Animations/FadeDown'
import FadeRight from './Animations/FadeRight'
import FadeUp from './Animations/FadeUp'
import { renderCanvas } from './renderCanvas'
import { ScrollContext } from './ScrollObserver'

export default function Hero(): ReactElement {
  const ref = useRef<HTMLHeadingElement>(null)
  const { scrollY } = useContext(ScrollContext)

  let progress = 0
  const { current: elContainer } = ref

  if (elContainer) {
    progress = Math.min(1, scrollY / elContainer.clientHeight)
  }

  useEffect(() => {
    renderCanvas()
  }, [])

  return (
    <div>
      <h1 className="sr-only">
        Hello I'm Patrick Sibale, I'm a software developer, and I love building things for the web.
      </h1>
      <motion.div
        className="relative z-10 flex h-[calc(100vh-81px)] items-center justify-center md:h-[calc(100vh-116px)]"
        animate={{
          transform: `translateY(${progress * 20}vh)`,
        }}
        transition={{ type: 'spring', stiffness: 50 }}
      >
        <AnimatePresence>
          <div className="mx-auto w-screen max-w-3xl px-4 sm:px-9 xl:max-w-5xl xl:px-0">
            <div className="flex cursor-default flex-col space-y-2 items-center">
              <FadeUp duration={0.6}>
                <h1 className="text-6xl font-semibold sm:text-5xl md:text-9xl xl:text-10xl text-center" 
                  style={{ fontFamily: 'Barcelony' }}>
                  Patrick Sibale
                </h1>
              </FadeUp>
              <FadeUp duration={0.6} delay={0.2}>
                <h4 className="text-xl font-small opacity-80 sm:text-2xl md:text-4xl xl:text-5xl text-center mt-8" 
                  style={{ fontFamily: 'Sephir' }}>
                  Crafting websites that balance aesthetics with seamless functionality.
                </h4>
              </FadeUp>
              <FadeRight duration={0.5} delay={0.8}>
                <div className="text-center mt-6 flex flex-wrap justify-center gap-6">
                  <Link
                    to="/about"
                    className="group text-md w-max cursor-pointer sm:text-lg md:text-xl xl:text-2xl relative"
                    style={{fontFamily: 'Swanley'}}
                  >
                    <span className="relative inline-block transition-transform duration-300 group-hover:translate-x-1">
                      Read more about me 
                      <span className="inline-block ml-1 transition-transform duration-300 group-hover:translate-x-1">&rarr;</span>
                    </span>
                    <span className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-primary-500 to-sunset-DEFAULT scale-x-0 transition-transform duration-300 origin-left group-hover:scale-x-100"></span>
                  </Link>
                  
                  <Link
                    to="/works"
                    className="group text-md w-max cursor-pointer sm:text-lg md:text-xl xl:text-2xl relative"
                    style={{fontFamily: 'Swanley'}}
                  >
                    <span className="relative inline-block transition-transform duration-300 group-hover:translate-x-1">
                      Check out my work
                      <span className="inline-block ml-1 transition-transform duration-300 group-hover:translate-x-1">&rarr;</span>
                    </span>
                    <span className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-sunset-DEFAULT to-primary-500 scale-x-0 transition-transform duration-300 origin-left group-hover:scale-x-100"></span>
                  </Link>
                </div>
              </FadeRight>
            </div>
            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 transform md:bottom-8">
              <div
                role="presentation"
                className="flex cursor-pointer flex-col items-center justify-center"
                onClick={() => {
                  const intro = document.querySelector('#intro')
                  intro?.scrollIntoView({ behavior: 'smooth' })
                }}
              >
                <FadeDown duration={1} delay={1.2}>
                  <HiOutlineArrowNarrowDown size={20} />
                </FadeDown>
              </div>
            </div>
          </div>
        </AnimatePresence>
      </motion.div>
      <canvas className="bg-skin-base pointer-events-none absolute inset-0" id="canvas"></canvas>
    </div>
  )
}
