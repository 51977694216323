
'use client'
import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import styles from './style.module.css';
import ProjectItem from './ProjectItem';

export default function SlidingProjectGallery() {
    const [isTitleVisible, setIsTitleVisible] = useState(false);
    const titleRef = useRef<HTMLHeadingElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsTitleVisible(true);
                }
            },
            {
                threshold: 0.1,
                rootMargin: '-50px'
            }
        );

        if (titleRef.current) {
            observer.observe(titleRef.current);
        }

        return () => {
            if (titleRef.current) {
                observer.unobserve(titleRef.current);
            }
        };
    }, []);

    const projects = [
        {
            id: 1,
            title: "NRGym Website",
            description: "Design & Full-Stack Development",
            imageUrl: "/images/nrgym.gif"
        },
        {
            id: 2,
            title: "InTech Systems",
            description: "Single-Page Website Development",
            imageUrl: "/images/intechsystemsgroup.gif"
        },
        {
            id: 3,
            title: "Brydg Capital",
            description: "Development of components",
            imageUrl: "/images/brydg.gif"
        },
        {
            id: 4,
            title: "Nyasalands Real-Estate",
            description: "Design & Full-Stack Development",
            imageUrl: "/images/nyasalandsfinal.gif"
        },
        {
            id: 5,
            title: "School-Yathu Edu-Management-Suite",
            description: "Design & Full-Stack Development",
            imageUrl: "/images/schoolyathu.gif"
        },
        {
            id: 6,
            title: "Ninjo.io",
            description: "Front-End Development",
            imageUrl: "/images/ninjo.png"
        },
        {
            id: 7,
            title: "Undefiled Religion",
            description: "Design and Development",
            imageUrl: "/images/und.gif"
        }
    ];

    return (
        <main className={styles.main}>
            <div className={styles.gallery}>
                <h2 
                    ref={titleRef}
                    className={`${styles.title} ${isTitleVisible ? styles.titleVisible : ''}`}
                    style={{ fontFamily: 'Sephir' }}
                >
                    Websites I've Crafted
                </h2>
                {projects.map(project => (
                    <ProjectItem key={project.id} project={project} />
                ))}
                <div className="flex justify-center mt-12">
                    <motion.button
                        onClick={() => navigate('/works')}
                        className="px-6 py-3 rounded-full flex items-center gap-2 text-slate-500 bg-slate-100/80 dark:bg-slate-800/80
                          shadow-[-5px_-5px_10px_rgba(255,_255,_255,_0.8),_5px_5px_10px_rgba(0,_0,_0,_0.25)]
                          transition-all duration-300
                          hover:shadow-[-1px_-1px_5px_rgba(255,_255,_255,_0.6),_1px_1px_5px_rgba(0,_0,_0,_0.3),inset_-2px_-2px_5px_rgba(255,_255,_255,_1),inset_2px_2px_4px_rgba(0,_0,_0,_0.3)]
                          hover:text-primary-500"
                        whileTap={{ scale: 0.98 }}
                    >
                        <span>View More Details</span>
                        <FiArrowRight />
                    </motion.button>
                </div>
            </div>
        </main>
    );
}
